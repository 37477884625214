.main-view-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  .main-view-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
  }


  .navigation {
    width: 100%;
    padding: 15px;

    > div {
      display: flex;
      align-items: center;
    }

    .left {
      display: flex;
      gap: 15px;
    }
  }

  .main-view-component-container {
    flex-grow: 1;
  }
}