@import '../../../variables.scss';

.chat-input-container {
  width: 100%;

  .compose-message-body {
    width: 100% !important;
    margin: 15px 0 0 !important;
  }
  
  .error-box-container {
    margin-top: 10px;
  }
}