@import '../../variables.scss';

.navigation {
  display: flex;
  align-items: center;;

  .left {
    flex: 1;
    height: 40px;
  }
  .center {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    overflow: hidden;

    .title {
      text-align: center;
      margin: 0;
      font-size: 20px;
      cursor: default;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
  .right {
    flex: 1;
    height: 40px;
  }
}