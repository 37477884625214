$primary: #546a7b;
$secondary: #62929e;
$tertiary: #c6c5b9;
$quaternary: #fdfdff;

$dark-gray: #393d3f;
$gray: #808080;

$background-gray: #dfdfdf;
$background-gray-light: #e9e8e8;
$background-gray-ultralight: #f3efee;

$standard-padding: 15px;
$standard-border-radius: 10px;
$standard-gap: 10px;

// chat
$border-thickness: 2px;
