@import '../../variables.scss';

.chat-messages-window {
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  overflow-y: hidden;
  align-items: center;
}
