.lost-page {
  display: flex;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
  min-width: 150px;
}

.lost-page-content {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lost-gif {
  width: 100%;
}