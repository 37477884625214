.nodes-container {
  width: 100%;
  display: flex;

  justify-content: flex-end;
  flex-grow: 1;
  // overflow-y: hidden;
  // align-items: center;
  padding: 15px;

  .node-container {
    flex-grow: 1;
    // background-color: pink;
    padding-left: 15px;

    height: 100%;
    overflow-y: auto;

    .node-title {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}
