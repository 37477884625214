.properties-header {
  display: flex;
}

.node-properties {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .node-property {
    display: flex;
    gap: 10px;

    .node-property-name {
      font-weight: 600;
    }
    .node-property-value {
      flex-grow: 1;
    }
  }
}