@import '../../../variables.scss';

.edge-container {
  padding: 10px;
  border-radius: 8px;
  background-color: $background-gray;
  display: flex;
  flex-direction: column;
}

.edges-as-node-b-list, .edges-as-node-a-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edges-as-node-a-header, .edges-as-node-b-header {
  display: flex;
}
