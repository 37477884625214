.display-nodes {
  display: flex;
  flex-direction: column;
  
  .nodes-list {
    width: 200px;
  }

  .nodes-by-edges-container {
    width: 300px;

    // .type-name {}

    .nodes-for-edge-type {
      padding-left: 30px;

      // .node-b-name {}
      
      .nodes-for-node-b-and-edge-type {
        padding-left: 30px;
      }
    }
  }
}  
