@import '../../variables';

// .error-box-container {}
.error-box-inner {
  border: 1px solid darken($tertiary, 60%);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: $tertiary;

  p {
    margin: 0;
  }
}